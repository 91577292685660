<template>
    <div>
        <div class="card card-custom gutter-b">
            <div class="card-header">
                <div class="card-title">
                <h3 class="card-label">Profil PTSP</h3>
                </div>
            </div>
            <div class="card-body">
                <!--begin::Example-->
                <div class="example mb-10">
                    <h4 class="mb-10 font-weight-bold text-dark">INFORMASI IDENTITAS PTSP</h4>
                <!--begin::Input-->
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Nama Daerah</label>
                        <div class="col-lg-8">
                            <input disabled type="text" v-model="profil.daerah" class="form-control"/>
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-4">Nomenklatur Sesuai Permendagri No. 25 Tahun 2021</label>
                        <div class="col-3">
                            <b-form-select v-model="profil.nomenklatur_permendagri" :options="permendagri" style="width: 150px;"></b-form-select>
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Nomenklatur Penyelenggara PTSP</label>
                        <div class="col-lg-8">
                            <input type="text" v-model="profil.nomenklatur_ptsp" class="form-control"/>
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Tahun Pembentukan PTSP</label>
                        <div class="col-lg-8">
                            <input type="number" min="0" v-model="profil.tahun_ptsp" class="form-control"/>
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Alamat Kantor</label>
                        <div class="col-lg-8">
                            <input type="text" v-model="profil.alamat" class="form-control"/>
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label"></label>
                        <div class="col-lg-8">
                            <div class="row">
                                <div class="col-xl-6">
                                    <!--begin::Input-->
                                    <div class="form-group">            
                                        <label>Provinsi</label>
                                            <b-form-select @input="getkota(profil.provinsi)" v-model="profil.provinsi">
                                            <option
                                                v-for="(dataprovinsi, k) in provinsi"
                                                :key="k"
                                                :value="dataprovinsi.name"
                                            >
                                                {{ dataprovinsi.name }}
                                            </option>
                                            </b-form-select>
                                    </div>
                                     <!--end::Input-->            
                                    </div>
                                            <div class="col-xl-6">
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <label>Kota</label>
                                                    <b-form-select v-model="profil.kabkota">
                                                        <option
                                                            v-for="(datakota, k) in kota"
                                                            :key="k"
                                                            :value="datakota.name"
                                                        >
                                                            {{ datakota.name }}
                                                        </option>
                                                    </b-form-select>
                                                    <!-- {{profil.kabkota}} -->
                                                </div>
                                     <!--end::Input-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label"></label>
                                    <div class="col-lg-8">
                                        <div class="row">
                                            <div class="col-xl-6">
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <label>Kode Pos</label>
                                                    <input min="0" type="number" v-model="profil.kodepos" class="form-control"/>
                                                </div>
                                                <!--end::Input-->
                                            </div>
                                            <div class="col-xl-6">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">No Telp/Fax</label>
                                    <div class="col-lg-8">
                                        <div class="row">
                                            <div class="col-xl-6">
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <input min="0" type="number" v-model="profil.notelp" class="form-control" />
                                                </div>
                                                <!--end::Input-->
                                            </div>
                                            <div class="col-xl-6">
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <input min="0" type="number" v-model="profil.nofax" class="form-control"/>
                                                </div>
                                                <!--end::Input-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Situs</label>
                                    <div class="col-lg-8">
                                        <input type="text" v-model="profil.website" class="form-control"/>
                                        
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Email</label>
                                    <div class="col-lg-8">
                                        <input type="email" v-model="profil.email" class="form-control"/>
                                        
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Media Sosial</label>
                                    <div class="col-lg-8">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <i class="socicon-facebook text-primary mr-5"></i>

                                                </span>
                                            </div>
                                            <input type="text"  v-model="profil.facebook" class="form-control"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label"></label>
                                    <div class="col-lg-8">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <i class="socicon-instagram text-primary mr-5"></i>

                                                </span>
                                            </div>
                                            <input type="text" v-model="profil.instagram" class="form-control"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label"></label>
                                    <div class="col-lg-8">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <i class="socicon-twitter text-primary mr-5"></i>

                                                </span>
                                            </div>
                                            <input type="text" v-model="profil.twitter" class="form-control"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Jumlah SDM  (Nilai akan terisi secara otomatis setelah memasukkan data kriteria SDM)</label>
                                        <div class="col-lg-8">
                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">            
                                                        <label>Bidang Pelayanan</label>
                                                            <input min="0" disabled type="number" v-model="profil.sdm_pelayanan" class="form-control"/>
                                                    </div>
                                     <!--end::Input-->            
                                                </div>
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">
                                                        <label>Bidang Dalaks</label>
                                                         <input min="0" disabled type="number" v-model="profil.sdm_dalaks" class="form-control"/>
                                                    </div>
                                        <!--end::Input-->
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Jumlah perizinan berusaha yang dilayani</label>
                                    <div class="col-lg-8">
                                        <input type="number" min="0" v-model="profil.perizinan_dilayani" class="form-control"/>
                                        
                                    </div>
                                </div> -->
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Jumlah aktivitas pendukung perizinan berusaha yang dilayani</label>
                                    <div class="col-lg-8">
                                        <input type="number" min="0" v-model="profil.aktifitas_dilayani" class="form-control"/>
                                        
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Nama Kepala Dinas</label>
                                    <div class="col-lg-8">
                                        <input type="text" v-model="profil.nama_kepala" class="form-control"/>
                                        
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Narahubung</label>
                                    <div class="col-lg-8">
                                        <div class="row">
                                            <div class="col-xl-4">
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                
                                                                <i class="flaticon-avatar"></i>
            
                                                            </span>
                                                        </div>
                                                        <input type="text" v-model="profil.nama_contact_person1" class="form-control"/>
                                                    </div>
                                                </div>
                                                <!--end::Input-->
                                            </div>
                                            <div class="col-xl-4">
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <i class="flaticon2-phone"></i>
                                                            </span>
                                                        </div>
                                                        <input type="number" v-model="profil.no_contact_person1" class="form-control"/>
                                                    </div>
                                                </div>
                                                <!--end::Input-->
                                            </div>
                                            <div class="col-xl-4">
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <i class="flaticon-email"></i>
                                                            </span>
                                                        </div>
                                                        <input type="email" v-model="profil.email_contact_person1" class="form-control"/>
                                                    </div>
                                                </div>
                                                <!--end::Input-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label"></label>
                                    <div class="col-lg-8">
                                        <div class="row">
                                            <div class="col-xl-4">
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                
                                                                <i class="flaticon-avatar"></i>
            
                                                            </span>
                                                        </div>
                                                        <input type="text" v-model="profil.nama_contact_person2" class="form-control"/>
                                                    </div>
                                                </div>
                                                <!--end::Input-->
                                            </div>
                                            <div class="col-xl-4">
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <i class="flaticon2-phone"></i>
                                                            </span>
                                                        </div>
                                                        <input min="0" type="number" v-model="profil.no_contact_person2" class="form-control"/>
                                                    </div>
                                                </div>
                                                <!--end::Input-->
                                            </div>
                                            <div class="col-xl-4">
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <i class="flaticon-email"></i>
                                                            </span>
                                                        </div>
                                                        <input type="email" v-model="profil.email_contact_person2" class="form-control"/>
                                                    </div>
                                                </div>
                                                <!--end::Input-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Titik Koordinat</label>
                                    <div class="col-lg-8">
                                        <div class="row">
                                            <div class="col-xl-4">
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <b>latitude</b>
                                                            </span>
                                                        </div>
                                                        <input type="text" v-model="profil.latitude" class="form-control"/>
                                                    </div>
                                                </div>
                                                <!--end::Input-->
                                            </div>
                                            <div class="col-xl-4">
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <b>longitude</b>
                                                            </span>
                                                        </div>
                                                         <input type="text" v-model="profil.longtitude" class="form-control"/>
                                                    </div>
                                                </div>
                                                <!--end::Input-->
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label"></label>
                                    <div class="col-lg-8">
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <label>Upload Foto Tampak Depan Kantor</label>
                                                    <div></div>
                                                    <b-form-file accept="image/*" v-model="profil.tampak_depan"  type="file" ref="file"></b-form-file>
                                                    <!-- <div class="custom-file">
                                                        <input type="file" class="custom-file-input" id="customFile"/>
                                                        <label class="custom-file-label" for="customFile">Choose file</label>
                                                    </div> -->
                                                </div>
                                                <b-img :src="profil.gambarptsp" style="height: 100px" fluid alt="Foto"></b-img>
                                                <!--end::Input-->
                                            </div>
                                        </div>
                                        <!-- <div v-if="status.status_sistem === 'Buka'"> -->
                                        <!-- <button type="button" @click="editprofil" style="float: right" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"><i class="fas fa-save"></i>Simpan</button> -->
                                        <!-- </div> -->
                                        
                                        
                                    </div>
                                </div>
                                
                                <button type="button" @click="editprofil" style="float: right" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"><i class="fas fa-save"></i>Simpan</button>
                </div>
                <!--end::Example-->
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import Swal from "sweetalert2";
import Vue from "vue";
// @import "vue-select/src/scss/vue-select.scss";

export default {
  name: "Profil-PTSP",
  components: {vSelect},
  data() {
    return {
    url: localStorage.getItem('baseapi'),
    token: localStorage.getItem('id_token'),
    idprofil: localStorage.getItem('idprofil'),
    user: JSON.parse(localStorage.getItem('user')),
    tanggal: localStorage.getItem('tanggal'),
    status:[],
    //   tabIndex: 0
    selectedpermendagri: null,
       permendagri: [
         { value: null, text: 'Silahkan Pilih' },
         { value: 'Sesuai', text: 'Sesuai' },
         { value: 'Belum sesuai', text: 'Belum sesuai' },
       ],
       selectedprovinsi:"",
       provinsi:[{
          id:"",
          name:"",
      }],
      selectedkota:"",
      kota:[{
          id:"",
          name:"",
      }],
      idprofil:null,
      toastCount:0,
      profil:[],
      survey:[],
      idsurvey:null,
      ketersediaan: [
        { value: 'Ada', text: 'Ada' },
        { value: 'Tidak', text: 'Tidak' },
      ],
      anggaran2020:0,
      anggaran2021:0,
      anggaran2022:0,
    };
  },
  watch: {
    "survey.s_3a": function (newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      Vue.nextTick(() => (this.survey.s_3a = result));
      var totalbaru = result.split(".").join("");

      this.anggaran2020 = parseInt(totalbaru);
    },
    "survey.s_3b": function (newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      Vue.nextTick(() => (this.survey.s_3b = result));
      var totalbaru = result.split(".").join("");

      this.anggaran2021 = parseInt(totalbaru);
    },
    "survey.s_3c": function (newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      Vue.nextTick(() => (this.survey.s_3c = result));
      var totalbaru = result.split(".").join("");

      this.anggaran2022 = parseInt(totalbaru);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profil PTSP" }]);
  },
  methods: {
      getprovinsi(){
        axios.get(this.url+'/provinsis', {
              headers: {
                  'xth': this.token
              }
            }).then(response => {
            this.provinsi = response.data;
        });
      },
      getkota(id){
        axios.get(this.url+`/kabkota/${id}`, {
              headers: {
                  'xth': this.token
              }
            }).then(response => {
            this.kota = response.data;
        });
      },
      geteditkota(){
        axios.get(this.url+`/kabkota/`+this.profil.provinsi, {
              headers: {
                  'xth': this.token
              }
            }).then(response => {
            this.kota = response.data;
        });
      },
    editprofil(){
          let formData = new FormData();
                formData.append('nomenklatur_ptsp', this.profil.nomenklatur_ptsp);
                formData.append('nomenklatur_permendagri', this.profil.nomenklatur_permendagri);
                formData.append('tahun_ptsp', this.profil.tahun_ptsp);
                formData.append('alamat', this.profil.alamat);
                formData.append('provinsi', this.profil.provinsi);
                formData.append('kabkota', this.profil.kabkota);
                formData.append('kodepos', this.profil.kodepos);
                formData.append('longtitude', this.longitude);
                formData.append('latitude', this.latitude);
                formData.append('notelp', this.profil.notelp);
                formData.append('nofax', this.profil.nofax);
                formData.append('website', this.profil.website);
                formData.append('email', this.profil.email);
                formData.append('facebook', this.profil.facebook);
                formData.append('instagram', this.profil.instagram);
                formData.append('twitter', this.profil.twitter);
                // formData.append('perizinan_dilayani', this.profil.perizinan_dilayani);
                formData.append('nama_kepala', this.profil.nama_kepala);
                formData.append('aktifitas_dilayani', this.profil.aktifitas_dilayani);
                formData.append('nama_contact_person1', this.profil.nama_contact_person1);
                formData.append('no_contact_person1', this.profil.no_contact_person1);
                formData.append('email_contact_person1', this.profil.email_contact_person1);
                formData.append('nama_contact_person2', this.profil.nama_contact_person2);
                formData.append('no_contact_person2', this.profil.no_contact_person2);
                formData.append('email_contact_person2', this.profil.email_contact_person2);
                formData.append('tampak_depan', this.profil.tampak_depan);
                // tampak_depan
          axios
            .post(this.url+'/profil/'+this.idprofil, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'xth': this.token
                }
            })
            .then(response => {
              this.$bvToast.toast('Berhasil Diupdate', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.loaddata()
                return response
              
            })
            .catch(error=>{
            console.log(error)
            this.$bvToast.toast('Gagal Diupdate', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            return error
            })

        //  axios
        //     .put(
        //     this.url+`/survey/`+this.idsurvey,
        //     {
        //         // idptsp,
        //         s_1:this.survey.s_1,
        //         s_2:this.survey.s_2,
        //         s_3a:this.anggaran2020,
        //         s_3b:this.anggaran2021,
        //         s_3c:this.anggaran2022,
        //         s_4:this.survey.s_4,
        //         s_5:this.survey.s_5,
        //         s_6:this.survey.s_6,
        //         s_7:this.survey.s_7,
        //         s_8:this.survey.s_8
        //     }
        //     ,{
        //         headers:{
        //         "xth": this.token
        //         }
        //     })
        //     .then((res) => {
        //         // this.$bvToast.toast('Berhasil Diedit', {
        //         //     title: `Success `,
        //         //     variant: `success`,
        //         //     solid: true
        //         // })
        //         this.loaddata()
        //     return res;
        //     })
        //     .catch((err) => {
        //     console.log(err)
        //     this.$bvToast.toast('Gagal Diedit', {
        //     title: `Failed `,
        //     variant: `danger`,
        //     solid: true
        //     })
        //     return err;
        // });
    },
    getlocation(){
         if(!("geolocation" in navigator)) {
        this.errorStr = 'Geolocation is not available.';
        return;
                }

                this.gettingLocation = true;
                // get position
                navigator.geolocation.getCurrentPosition(pos => {
                this.gettingLocation = false;
                this.location = pos;
                this.latitude = pos.coords.latitude;
                this.longitude = pos.coords.longitude;
               
                }, err => {
                this.gettingLocation = false;
                this.errorStr = err.message;
                })
    },
    loaddata(){
        axios.get(this.url+`/profils?filter=idptsp,=,`+this.$route.params.id, {
              headers: {
                  'xth': this.token
              }
            }).then(response => {
            this.profil = response.data.data;
            this.idprofil = response.data.data.id;
            this.profil['gambarptsp'] = this.profil.tampak_depan
        });

        axios.get(this.url+`/survey?filter=idptsp,=,`+this.$route.params.id+`;tahun,=,2022`, {
              headers: {
                  'xth': this.token
              }
            }).then(response => {
            this.survey = response.data.data[0];
            this.idsurvey = response.data.data[0].id;
        });
    },    
  },
  created(){
      this.getprovinsi();
      this.loaddata();
      this.getlocation()
  }

};
</script>